import * as React from 'react'
import { Textfit } from 'react-textfit'

const LocalTextFit = ({ children, ...props }) => {
	const textRef = React.useRef()

	React.useEffect(() => {
		const timer = setTimeout(() => {
			if (textRef.current.handleWindowResize) {
				textRef.current.handleWindowResize()
			}
		}, 1000)

		return () => {
			clearTimeout(timer)
		}
	}, [textRef.current])

	if (children) {
		return (
			<Textfit mode="single" max={40} ref={textRef} {...props}>
				{children}
			</Textfit>
		)
	} else {
		return <></>
	}
}

export default LocalTextFit
