import * as React from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'
import H3SVG from '../icons/H3.js'
import LocalTextFit from './textFit.js'

const Note = (props) => {
	const { color, text, disableTextFit, backgroundOpacity = 0.7 } = props

	return (
		<Box position="relative" variant="note" padding={4}>
			<Box position="absolute" top="0" bottom="0" right="0" left="0">
				<H3SVG fillOpacity={backgroundOpacity} />
			</Box>

			{disableTextFit ? (
				<Text position="relative">{text}</Text>
			) : (
				<Heading
					as="h3"
					color={color}
					position="relative"
					margin={'0 !important'}
				>
					<LocalTextFit>{text}</LocalTextFit>
				</Heading>
			)}
		</Box>
	)
}

export default Note
