import React from 'react'

const H3SVG = ({ fillOpacity = 0.7 }) => {
	return (
		<svg
			viewBox="0 0 335 73"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			preserveAspectRatio="none"
		>
			<path
				d="M0 2.02332L335 0V73L0 67.5V2.02332Z"
				fill="white"
				fillOpacity={fillOpacity}
			/>
		</svg>
	)
}

export default H3SVG
